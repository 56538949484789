import { ROLES_LIST, DASHBOARD_ROLES, REQUEST_ROLES, INSPECTIONS_ROLES, INCIDENT_ROLES, MANAGE_ATTACHMENT_ROLES,
  APPROVAL_WORKFLOW_LIST_ROLES,CONTACT_REPORTS_ROLES, VEHICLE_ASSIGNMENT_REPORTS_ROLES, VEHICLE_REPORTS_ROLES,
  CURRENT_USER_DETAILS, FLEET_ADMIN_ROLE, FUEL_HISTORY_REPORTS_ROLES, METER_HISTORY_REPORTS_ROLES, OPERATOR_REPORTS_ROLES,
  DEPRECIATION_REPORTS_ROLES, VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES, VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES, VEHICLE_SUMMARY_REPORT_ROLES,
  VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES, DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES, DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES,
  DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES, REPORT_ROLES, INCIDENT_LIST,CORRECTIVE_TASKS, WATCH_ROLES } from 'src/app/shared/data/constants';
import { RouteInfo } from './sidebar.metadata';
import { RouterOutlet } from '@angular/router';

var routesRoles = {};
routesRoles = ROLES_LIST
export const ROUTES: RouteInfo[] = [
  // {
  //   path: "",
  //   title: "MENUITEMS.MAIN.TEXT",
  //   iconType: "",
  //   icon: "",
  //   class: "",
  //   groupTitle: true,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["All"],
  //   submenu: [],
  // },

  // {
  //   path: "",
  //   title: "Extra Pages",
  //   iconType: "material-icons-two-tone",
  //   icon: "description",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["All"],
  //   submenu: [],
  // },

  {
    path: "dashboard",
    title: "MAIN_MENU.DASHBOARD",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [DASHBOARD_ROLES.create, DASHBOARD_ROLES.delete, DASHBOARD_ROLES.edit, DASHBOARD_ROLES.export, DASHBOARD_ROLES.print, DASHBOARD_ROLES.readonly],
    submenu: [],
  },

  {
    path: "approval-workflow-list",
    title: "MAIN_MENU.APPROVAL_WORKFLOW_LIST",
    iconType: "material-icons-two-tone",
    icon: "verified",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ROLES_LIST.APPROVAL_WORKFLOW_LIST_CREATE,ROLES_LIST.APPROVAL_WORKFLOW_LIST_EDIT ,ROLES_LIST.APPROVAL_WORKFLOW_LIST_EXPORT ,ROLES_LIST.APPROVAL_WORKFLOW_LIST_PRINT ,ROLES_LIST.APPROVAL_WORKFLOW_LIST_READONLY,ROLES_LIST.APPROVAL_WORKFLOW_LIST_DELETE],
    submenu: [],
  },
  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.ROUTE",
  //   iconType: "material-icons-two-tone",
  //   icon: "alt_route",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ "Admin"],
  //   submenu: [],
  // },

  {
    path: "",
    title: "MAIN_MENU.VEHICLES",
    iconType: "material-icons-two-tone",
    icon: "directions_car",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ROLES_LIST.VEHICLE_ASSIGNMENT_CREATE,ROLES_LIST.VEHICLE_ASSIGNMENT_EDIT,ROLES_LIST.VEHICLE_ASSIGNMENT_EXPORT,ROLES_LIST.VEHICLE_ASSIGNMENT_DELETE,ROLES_LIST.VEHICLE_ASSIGNMENT_PRINT,ROLES_LIST.VEHICLE_ASSIGNMENT_READONLY,
      ROLES_LIST.VEHICLE_LIST_CREATE,ROLES_LIST.VEHICLE_LIST_EDIT,ROLES_LIST.VEHICLE_LIST_EXPORT,ROLES_LIST.VEHICLE_LIST_DELETE,ROLES_LIST.VEHICLE_LIST_PRINT,ROLES_LIST.VEHICLE_LIST_READONLY,
      ROLES_LIST.MANAGE_VEHICLES_CREATE,ROLES_LIST.MANAGE_VEHICLES_EDIT,ROLES_LIST.MANAGE_VEHICLES_EXPORT,ROLES_LIST.MANAGE_VEHICLES_PRINT,ROLES_LIST.MANAGE_VEHICLES_READONLY,ROLES_LIST.MANAGE_VEHICLES_DELETE,
      ROLES_LIST.VEHICLE_MAINTENANCE_CREATE,ROLES_LIST.VEHICLE_MAINTENANCE_EDIT,ROLES_LIST.VEHICLE_MAINTENANCE_EXPORT,ROLES_LIST.VEHICLE_MAINTENANCE_PRINT,ROLES_LIST.VEHICLE_MAINTENANCE_READONLY,ROLES_LIST.VEHICLE_MAINTENANCE_DELETE,
      ROLES_LIST.VEHICLE_UNAVAILABILITY_CREATE,ROLES_LIST.VEHICLE_UNAVAILABILITY_EDIT,ROLES_LIST.VEHICLE_UNAVAILABILITY_EXPORT,ROLES_LIST.VEHICLE_UNAVAILABILITY_PRINT,ROLES_LIST.VEHICLE_UNAVAILABILITY_READONLY,ROLES_LIST.VEHICLE_UNAVAILABILITY_DELETE,
      ROLES_LIST.EXPENSES_HISTORY_CREATE, ROLES_LIST.EXPENSES_HISTORY_DELETE, ROLES_LIST.EXPENSES_HISTORY_EDIT, ROLES_LIST.EXPENSES_HISTORY_EXPORT, ROLES_LIST.EXPENSES_HISTORY_PRINT, ROLES_LIST.EXPENSES_HISTORY_READONLY,
      ROLES_LIST.METER_HISTORY_CREATE, ROLES_LIST.METER_HISTORY_DELETE, ROLES_LIST.METER_HISTORY_EDIT, ROLES_LIST.METER_HISTORY_EXPORT, ROLES_LIST.METER_HISTORY_PRINT, ROLES_LIST.METER_HISTORY_READONLY,
      ROLES_LIST.FUEL_HISTORY_CREATE, ROLES_LIST.FUEL_HISTORY_DELETE, ROLES_LIST.FUEL_HISTORY_EDIT, ROLES_LIST.FUEL_HISTORY_EXPORT, ROLES_LIST.FUEL_HISTORY_PRINT, ROLES_LIST.FUEL_HISTORY_READONLY,
      ROLES_LIST.VEHICLE_ACCESSORIES_CREATE, ROLES_LIST.VEHICLE_ACCESSORIES_EDIT, ROLES_LIST.VEHICLE_ACCESSORIES_EXPORT, ROLES_LIST.VEHICLE_ACCESSORIES_PRINT, ROLES_LIST.VEHICLE_ACCESSORIES_READONLY, ROLES_LIST.VEHICLE_ACCESSORIES_DELETE],
    submenu: [{
      path: "vehicles/list",
      title: "VEHICLE_MENU.VEHICLE_LIST",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.VEHICLE_LIST_CREATE,ROLES_LIST.VEHICLE_LIST_EDIT,ROLES_LIST.VEHICLE_LIST_EXPORT,ROLES_LIST.VEHICLE_LIST_DELETE,ROLES_LIST.VEHICLE_LIST_PRINT,ROLES_LIST.VEHICLE_LIST_READONLY],
      submenu: [],
    },
    {
      path: "vehicles/vehicle-maintainence",
      title: 'VEHICLE_MAINTAINENCE_MENU.MANAGE_VEHICLES',
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.MANAGE_VEHICLES_CREATE,ROLES_LIST.MANAGE_VEHICLES_EDIT,ROLES_LIST.MANAGE_VEHICLES_EXPORT,ROLES_LIST.MANAGE_VEHICLES_PRINT,ROLES_LIST.MANAGE_VEHICLES_READONLY,ROLES_LIST.MANAGE_VEHICLES_DELETE,
        ROLES_LIST.VEHICLE_MAINTENANCE_CREATE,ROLES_LIST.VEHICLE_MAINTENANCE_EDIT,ROLES_LIST.VEHICLE_MAINTENANCE_EXPORT,ROLES_LIST.VEHICLE_MAINTENANCE_PRINT,ROLES_LIST.VEHICLE_MAINTENANCE_READONLY,ROLES_LIST.VEHICLE_MAINTENANCE_DELETE,
        ROLES_LIST.VEHICLE_UNAVAILABILITY_CREATE,ROLES_LIST.VEHICLE_UNAVAILABILITY_EDIT,ROLES_LIST.VEHICLE_UNAVAILABILITY_EXPORT,ROLES_LIST.VEHICLE_UNAVAILABILITY_PRINT,ROLES_LIST.VEHICLE_UNAVAILABILITY_READONLY,ROLES_LIST.VEHICLE_UNAVAILABILITY_DELETE
      ],
      submenu: [
        {
          path: "vehicles/vehicle-maintainence",
          title: 'VEHICLE_MAINTAINENCE_MENU.VEHICLE_MAINTAINENCE',
          iconType: "material-icons-two-tone",
          class: "",
          groupTitle: false,
          icon: "",
          badge: "",
          badgeClass: "",
          role: [ROLES_LIST.VEHICLE_MAINTENANCE_CREATE,ROLES_LIST.VEHICLE_MAINTENANCE_EDIT,ROLES_LIST.VEHICLE_MAINTENANCE_EXPORT,ROLES_LIST.VEHICLE_MAINTENANCE_PRINT,ROLES_LIST.VEHICLE_MAINTENANCE_READONLY,ROLES_LIST.VEHICLE_MAINTENANCE_DELETE],
          submenu: [],
        },
        {
          path: "vehicles/vehicle-unavailability",
          title: 'VEHICLE_UNAVAILABILITY_MENU.VEHICLE_UNAVAILABILITY',
          iconType: "material-icons-two-tone",
          class: "",
          groupTitle: false,
          icon: "",
          badge: "",
          badgeClass: "",
          role: [ROLES_LIST.VEHICLE_UNAVAILABILITY_CREATE,ROLES_LIST.VEHICLE_UNAVAILABILITY_EDIT,ROLES_LIST.VEHICLE_UNAVAILABILITY_EXPORT,ROLES_LIST.VEHICLE_UNAVAILABILITY_PRINT,ROLES_LIST.VEHICLE_UNAVAILABILITY_READONLY,ROLES_LIST.VEHICLE_UNAVAILABILITY_DELETE],
          submenu: [],
        },
      ],
    },
    {
      path: "vehicles/assignments",
      title: "VEHICLE_MENU.VEHICLE_ASSIGNMENT",
      icon: "",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.VEHICLE_ASSIGNMENT_CREATE,ROLES_LIST.VEHICLE_ASSIGNMENT_EDIT,ROLES_LIST.VEHICLE_ASSIGNMENT_EXPORT,ROLES_LIST.VEHICLE_ASSIGNMENT_DELETE,ROLES_LIST.VEHICLE_ASSIGNMENT_PRINT,ROLES_LIST.VEHICLE_ASSIGNMENT_READONLY],
      submenu: [],
    },
    {
      path: "vehicles/meter-history",
      title: "VEHICLE_MENU.METER_HISTORY",
      icon: "",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [ ROLES_LIST.METER_HISTORY_CREATE, ROLES_LIST.METER_HISTORY_DELETE, ROLES_LIST.METER_HISTORY_EDIT, ROLES_LIST.METER_HISTORY_EXPORT, ROLES_LIST.METER_HISTORY_PRINT, ROLES_LIST.METER_HISTORY_READONLY],
      submenu: [],
    },
    {
      path: "vehicles/expensesHistory/list",
      title: "EXPENSES_HISTORY_MENU.EXPENSES_HISTORY",
      iconType: "material-icons-two-tone",
      class: "",
      icon: "",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [ ROLES_LIST.EXPENSES_HISTORY_CREATE, ROLES_LIST.EXPENSES_HISTORY_DELETE, ROLES_LIST.EXPENSES_HISTORY_EDIT, ROLES_LIST.EXPENSES_HISTORY_EXPORT, ROLES_LIST.EXPENSES_HISTORY_PRINT, ROLES_LIST.EXPENSES_HISTORY_READONLY],
      submenu: [],
    },
    {
      path: "fuelHistory/list",
      title: "MAIN_MENU.FUEL_HISTORY",
      iconType: "material-icons-two-tone",
      icon: "local_gas_station",
      class: "",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.FUEL_HISTORY_CREATE, ROLES_LIST.FUEL_HISTORY_DELETE, ROLES_LIST.FUEL_HISTORY_EDIT, ROLES_LIST.FUEL_HISTORY_EXPORT, ROLES_LIST.FUEL_HISTORY_PRINT, ROLES_LIST.FUEL_HISTORY_READONLY],
      submenu: [],
    },
    {
      path: "vehicles/accessories/list",
      title: "MAIN_MENU.EQUIPMENT",
      iconType: "material-icons-two-tone",
      icon: "home_repair_service",
      class: "",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.VEHICLE_ACCESSORIES_CREATE, ROLES_LIST.VEHICLE_ACCESSORIES_EDIT, ROLES_LIST.VEHICLE_ACCESSORIES_EXPORT, ROLES_LIST.VEHICLE_ACCESSORIES_PRINT, ROLES_LIST.VEHICLE_ACCESSORIES_READONLY, ROLES_LIST.VEHICLE_ACCESSORIES_DELETE],
      submenu: [],
    },
  ],
  },
  {
    path: "routing-management",
    title: "MAIN_MENU.ROUTING_MANAGEMENT",
    iconType: "material-icons-two-tone",
    icon: "pin_drop",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_CREATE,ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_DELETE,ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_EDIT,ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_EXPORT,ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_PRINT,ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_READONLY,
    ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_CREATE,ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_EDIT,ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_EXPORT,ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_PRINT,ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_READONLY,ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_DELETE,
    ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_CREATE,ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_EDIT,ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_EXPORT,ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_PRINT,ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_READONLY,ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_DELETE],
    submenu: [
      {
        path: "routing-management/check-point-list",
        title: "ROUTING_MANAGEMENT_MENU.CHECK_POINT_MASTER",
        iconType: "material-icons-two-tone",
        icon: "pin_drop",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_CREATE,ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_DELETE,ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_EDIT,ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_EXPORT,ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_PRINT,ROLES_LIST.ROUTE_MANAGEMENT_CHECKPOINT_MASTER_READONLY],
        submenu: [],
      },
      {
        path: "routing-management/route-master",
        title: "ROUTING_MANAGEMENT_MENU.ROUTE_MASTER",
        iconType: "material-icons-two-tone",
        icon: "pin_drop",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_CREATE,ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_EDIT,ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_EXPORT,ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_PRINT,ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_READONLY,ROLES_LIST.ROUTE_MANAGEMENT_ROUTE_MASTER_DELETE],
        submenu: [],
      },
      {
        path: "routing-management/ride-master",
        title: "ROUTING_MANAGEMENT_MENU.RIDE_MASTER",
        iconType: "material-icons-two-tone",
        icon: "pin_drop",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_CREATE,ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_EDIT,ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_EXPORT,ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_PRINT,ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_READONLY,ROLES_LIST.ROUTE_MANAGEMENT_RIDE_MASTER_DELETE],
        submenu: [],
      }
    ],
  },

  {
    path: "request",
    title: "MAIN_MENU.REQUEST",
    iconType: "material-icons-two-tone",
    icon: "car_crash",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [REQUEST_ROLES.create, REQUEST_ROLES.delete, REQUEST_ROLES.edit, REQUEST_ROLES.export, REQUEST_ROLES.print, REQUEST_ROLES.readonly],
    submenu: [],
  },

  {
    path: "",
    title: "MAIN_MENU.CONTACTS",
    iconType: "material-icons-two-tone",
    icon: "contact_phone",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ROLES_LIST.CONTACTS_LIST_CREATE,ROLES_LIST.CONTACTS_LIST_EDIT,ROLES_LIST.CONTACTS_LIST_EXPORT,ROLES_LIST.CONTACTS_LIST_PRINT,ROLES_LIST.CONTACTS_LIST_READONLY,ROLES_LIST.CONTACTS_LIST_DELETE,
          ROLES_LIST.OPERATOR_UNAVAILABILITY_CREATE, ROLES_LIST.OPERATOR_UNAVAILABILITY_DELETE, ROLES_LIST.OPERATOR_UNAVAILABILITY_EDIT, ROLES_LIST.OPERATOR_UNAVAILABILITY_EXPORT, ROLES_LIST.OPERATOR_UNAVAILABILITY_PRINT, ROLES_LIST.OPERATOR_UNAVAILABILITY_READONLY
    ],
    submenu: [{
      path: "contacts/list",
      title: "MAIN_MENU.CONTACTS",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.CONTACTS_LIST_CREATE,ROLES_LIST.CONTACTS_LIST_EDIT,ROLES_LIST.CONTACTS_LIST_EXPORT,ROLES_LIST.CONTACTS_LIST_PRINT,ROLES_LIST.CONTACTS_LIST_READONLY,ROLES_LIST.CONTACTS_LIST_DELETE],
      submenu: [],
    },
    {
      path: "contacts/manage-leaves",
      title: "CONTACT_MENU.MANAGE_LEAVES",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.OPERATOR_UNAVAILABILITY_CREATE, ROLES_LIST.OPERATOR_UNAVAILABILITY_DELETE, ROLES_LIST.OPERATOR_UNAVAILABILITY_EDIT, ROLES_LIST.OPERATOR_UNAVAILABILITY_EXPORT, ROLES_LIST.OPERATOR_UNAVAILABILITY_PRINT, ROLES_LIST.OPERATOR_UNAVAILABILITY_READONLY],
      submenu: [],
    },
  ],
  },

  // {
  //   path: "fuelHistory/list",
  //   title: "MAIN_MENU.FUEL_HISTORY",
  //   iconType: "material-icons-two-tone",
  //   icon: "local_gas_station",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ROLES_LIST.FUEL_HISTORY_CREATE, ROLES_LIST.FUEL_HISTORY_DELETE, ROLES_LIST.FUEL_HISTORY_EDIT, ROLES_LIST.FUEL_HISTORY_EXPORT, ROLES_LIST.FUEL_HISTORY_PRINT, ROLES_LIST.FUEL_HISTORY_READONLY],
  //   submenu: [],
  // },

  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.PLACES",
  //   iconType: "material-icons-two-tone",
  //   icon: "location_on",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ "Admin"],
  //   submenu: [],
  // },

  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.SERVICE",
  //   iconType: "material-icons-two-tone",
  //   icon: "build_circle",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ "Admin"],
  //   submenu: [],
  // },

  {
    path: "inspections",
    title: "MAIN_MENU.INSPECTIONS",
    iconType: "material-icons-two-tone",
    icon: "content_paste_search",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [INSPECTIONS_ROLES.create, INSPECTIONS_ROLES.delete, INSPECTIONS_ROLES.edit, INSPECTIONS_ROLES.export, INSPECTIONS_ROLES.print, INSPECTIONS_ROLES.readonly],
    submenu: [],
  },
  {
    path: "",
    title: "ISSUES.ISSUES",
    iconType: "material-icons-two-tone",
    icon: "warning",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [INCIDENT_ROLES.create, INCIDENT_ROLES.delete, INCIDENT_ROLES.edit, INCIDENT_ROLES.export, INCIDENT_ROLES.print, INCIDENT_ROLES.readonly,
      CORRECTIVE_TASKS.create, CORRECTIVE_TASKS.delete, CORRECTIVE_TASKS.edit, CORRECTIVE_TASKS.export, CORRECTIVE_TASKS.print, CORRECTIVE_TASKS.readonly
    ],
    submenu: [
      {
        path: "incident",
        title: "ISSUES.INCIDENT_LIST",
        iconType: "material-icons-two-tone",
        icon: "warning",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [INCIDENT_LIST.create, INCIDENT_LIST.delete, INCIDENT_LIST.edit, INCIDENT_LIST.export, INCIDENT_LIST.print, INCIDENT_LIST.readonly],
        submenu: []
      },
      {
        path: "incident/corrective-tasks",
        title: "ISSUES.CORRECTIVE_TASKS",
        iconType: "material-icons-two-tone",
        icon: "warning",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [CORRECTIVE_TASKS.create, CORRECTIVE_TASKS.delete, CORRECTIVE_TASKS.edit, CORRECTIVE_TASKS.export, CORRECTIVE_TASKS.print, CORRECTIVE_TASKS.readonly],
        submenu: []
      }
    ],
  },
  {
    path: "watch",
    title: "MAIN_MENU.WATCH",
    iconType: "material-icons-two-tone",
    icon: "timer",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [WATCH_ROLES.create, WATCH_ROLES.delete, WATCH_ROLES.edit, WATCH_ROLES.export, WATCH_ROLES.print, WATCH_ROLES.readonly],
    submenu: [],
  },

  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.REMINDERS",
  //   iconType: "material-icons-two-tone",
  //   icon: "alarm",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ "Admin"],
  //   submenu: [],
  // },

  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.DOCUMENTS",
  //   iconType: "material-icons-two-tone",
  //   icon: "file_present",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ "Admin"],
  //   submenu: [],
  // },

  {
    path: "",
    title: "MAIN_MENU.REPORTS",
    iconType: "material-icons-two-tone",
    icon: "bar_chart",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ "Admin",CONTACT_REPORTS_ROLES.create, CONTACT_REPORTS_ROLES.delete, CONTACT_REPORTS_ROLES.edit, CONTACT_REPORTS_ROLES.export, CONTACT_REPORTS_ROLES.print, CONTACT_REPORTS_ROLES.readonly,
      VEHICLE_ASSIGNMENT_REPORTS_ROLES.create, VEHICLE_ASSIGNMENT_REPORTS_ROLES.delete, VEHICLE_ASSIGNMENT_REPORTS_ROLES.edit, VEHICLE_ASSIGNMENT_REPORTS_ROLES.export, VEHICLE_ASSIGNMENT_REPORTS_ROLES.print, VEHICLE_ASSIGNMENT_REPORTS_ROLES.readonly,
      VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.create,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.delete,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.edit,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.export,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.print,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.readonly,
      VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.create,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.delete,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.edit,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.export,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.print,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.readonly,
      VEHICLE_REPORTS_ROLES.create, VEHICLE_REPORTS_ROLES.delete, VEHICLE_REPORTS_ROLES.edit, VEHICLE_REPORTS_ROLES.export, VEHICLE_REPORTS_ROLES.print, VEHICLE_REPORTS_ROLES.readonly,
      VEHICLE_SUMMARY_REPORT_ROLES.create,VEHICLE_SUMMARY_REPORT_ROLES.delete,VEHICLE_SUMMARY_REPORT_ROLES.edit,VEHICLE_SUMMARY_REPORT_ROLES.export,VEHICLE_SUMMARY_REPORT_ROLES.print,VEHICLE_SUMMARY_REPORT_ROLES.readonly,
      VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.create,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.delete,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.edit,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.export,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.print,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.readonly,
      FUEL_HISTORY_REPORTS_ROLES.create,FUEL_HISTORY_REPORTS_ROLES.delete,FUEL_HISTORY_REPORTS_ROLES.edit, FUEL_HISTORY_REPORTS_ROLES.export, FUEL_HISTORY_REPORTS_ROLES.export, FUEL_HISTORY_REPORTS_ROLES.readonly,
      METER_HISTORY_REPORTS_ROLES.create,METER_HISTORY_REPORTS_ROLES.delete,METER_HISTORY_REPORTS_ROLES.edit,METER_HISTORY_REPORTS_ROLES.export,METER_HISTORY_REPORTS_ROLES.print,METER_HISTORY_REPORTS_ROLES.readonly,
      OPERATOR_REPORTS_ROLES.create,OPERATOR_REPORTS_ROLES.delete,OPERATOR_REPORTS_ROLES.edit,OPERATOR_REPORTS_ROLES.export,OPERATOR_REPORTS_ROLES.print,OPERATOR_REPORTS_ROLES.readonly,
      DEPRECIATION_REPORTS_ROLES.create,DEPRECIATION_REPORTS_ROLES.delete,DEPRECIATION_REPORTS_ROLES.edit,DEPRECIATION_REPORTS_ROLES.export,DEPRECIATION_REPORTS_ROLES.print,DEPRECIATION_REPORTS_ROLES.readonly,
      DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.create,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.delete,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.edit,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.export,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.print,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.readonly,
      DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.create,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.delete,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.edit,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.export,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.print,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.readonly,
      DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.create,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.delete,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.edit,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.export,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.print,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.readonly,
    ],
    submenu: [
      {
        path: "",
        title: "REPORT_MENU.VEHICLE_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",VEHICLE_REPORTS_ROLES.create, VEHICLE_REPORTS_ROLES.delete, VEHICLE_REPORTS_ROLES.edit, VEHICLE_REPORTS_ROLES.export, VEHICLE_REPORTS_ROLES.print, VEHICLE_REPORTS_ROLES.readonly,
          VEHICLE_SUMMARY_REPORT_ROLES.create,VEHICLE_SUMMARY_REPORT_ROLES.delete,VEHICLE_SUMMARY_REPORT_ROLES.edit,VEHICLE_SUMMARY_REPORT_ROLES.export,VEHICLE_SUMMARY_REPORT_ROLES.print,VEHICLE_SUMMARY_REPORT_ROLES.readonly,
          VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.create,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.delete,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.edit,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.export,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.print,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.readonly],
        submenu: [
          {
            path: "reports/vehicleReports/list",
            title: "VEHICLE_SUMMARY_REPORT.VEHICLE_SUMMARY",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [ "Admin",VEHICLE_SUMMARY_REPORT_ROLES.create,VEHICLE_SUMMARY_REPORT_ROLES.delete,VEHICLE_SUMMARY_REPORT_ROLES.edit,VEHICLE_SUMMARY_REPORT_ROLES.export,VEHICLE_SUMMARY_REPORT_ROLES.print,VEHICLE_SUMMARY_REPORT_ROLES.readonly],
            submenu: [],
          },
          {
            path: "reports/vehicleRegistrationAndInsurance/list",
            title: "VEHICLE_REGISTRATION_AND_INSURANCE_REPORT.VEHICLE_REGISTRATION_AND_INSURANCE",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [ "Admin",VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.create,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.delete,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.edit,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.export,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.print,VEHICLE_REGISTRATION_AND_INSURANCE_REPORTS_ROLES.readonly],
            submenu: [],
          },
          // {
          //   path: "maintanance/next-phase",
          //   title: "Vehicle Renewal Reminders",
          //   iconType: "material-icons-two-tone",
          //   class: "",
          //   groupTitle: false,
          //   icon: "",
          //   badge: "",
          //   badgeClass: "",
          //   role: [ "Admin"],
          //   submenu: [],
          // },
          // {
          //   path: "maintanance/next-phase",
          //   title: "Utilization Summary",
          //   iconType: "material-icons-two-tone",
          //   class: "",
          //   groupTitle: false,
          //   icon: "",
          //   badge: "",
          //   badgeClass: "",
          //   role: [ "Admin"],
          //   submenu: [],
          // },
        ],
      },
      {
        path: "",
        title: "REPORT_MENU.VEHICLE_ASSIGNMENT_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: ["Admin",VEHICLE_ASSIGNMENT_REPORTS_ROLES.create, VEHICLE_ASSIGNMENT_REPORTS_ROLES.delete, VEHICLE_ASSIGNMENT_REPORTS_ROLES.edit, VEHICLE_ASSIGNMENT_REPORTS_ROLES.export, VEHICLE_ASSIGNMENT_REPORTS_ROLES.print, VEHICLE_ASSIGNMENT_REPORTS_ROLES.readonly,
          VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.create,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.delete,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.edit,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.export,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.print,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.readonly,
          VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.create,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.delete,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.edit,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.export,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.print,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.readonly],
        submenu: [
          {
            path: "reports/vehicleAssignmentReports/vehicleAssignmentLog",
            title: "REPORT_MENU.ASSIGNMENT_SUMMARY",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [ "Admin",VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.create,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.delete,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.edit,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.export,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.print,VEHICLE_ASSIGNMENT_SUMMARY_REPORTS_ROLES.readonly],
            submenu: [],
          },
          {
            path: "reports/vehicleAssignmentReports/vehicleAssignmentSummary",
            title: "REPORT_MENU.ASSIGNMENT_DETAILS",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [ "Admin",VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.create,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.delete,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.edit,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.export,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.print,VEHICLE_ASSIGNMENT_DETAIL_REPORTS_ROLES.readonly],
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "CONTACT_SUMMARY_REPORT.CONTACT_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: ["Admin",CONTACT_REPORTS_ROLES.create, CONTACT_REPORTS_ROLES.delete, CONTACT_REPORTS_ROLES.edit, CONTACT_REPORTS_ROLES.export, CONTACT_REPORTS_ROLES.print, CONTACT_REPORTS_ROLES.readonly],
        submenu: [
                {
        path: "reports/contact/list",
        title: "CONTACT_SUMMARY_REPORT.CONTACT_SUMMARY",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: ["Admin",CONTACT_REPORTS_ROLES.create, CONTACT_REPORTS_ROLES.delete, CONTACT_REPORTS_ROLES.edit, CONTACT_REPORTS_ROLES.export, CONTACT_REPORTS_ROLES.print, CONTACT_REPORTS_ROLES.readonly],
        submenu: [],
      },
        ],
      },
      {
        path: "reports/fuelHistoryReports/list",
        title: "REPORT_MENU.FUEL_HISTORY_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: ["Admin",FUEL_HISTORY_REPORTS_ROLES.create,FUEL_HISTORY_REPORTS_ROLES.delete,FUEL_HISTORY_REPORTS_ROLES.edit, FUEL_HISTORY_REPORTS_ROLES.export, FUEL_HISTORY_REPORTS_ROLES.export, FUEL_HISTORY_REPORTS_ROLES.readonly],
        submenu: [],
      },
      {
        path: "reports/meterHistoryReports/list",
        title: "REPORT_MENU.METER_HISTORY_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: ["Admin",METER_HISTORY_REPORTS_ROLES.create,METER_HISTORY_REPORTS_ROLES.delete,METER_HISTORY_REPORTS_ROLES.edit,METER_HISTORY_REPORTS_ROLES.export,METER_HISTORY_REPORTS_ROLES.print,METER_HISTORY_REPORTS_ROLES.readonly],
        submenu: [],
      },
      {
        path: "",
        title: "REPORT_MENU.OPERATOR_SUMMARY_REPORT",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: ["Admin",OPERATOR_REPORTS_ROLES.create,OPERATOR_REPORTS_ROLES.delete,OPERATOR_REPORTS_ROLES.edit,OPERATOR_REPORTS_ROLES.export,OPERATOR_REPORTS_ROLES.print,OPERATOR_REPORTS_ROLES.readonly],
        submenu: [
          {
            path: "reports/operatorSummaryReports/list",
            title: "REPORT_MENU.OPEARATOR_SUMMARY",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: ["Admin",OPERATOR_REPORTS_ROLES.create,OPERATOR_REPORTS_ROLES.delete,OPERATOR_REPORTS_ROLES.edit,OPERATOR_REPORTS_ROLES.export,OPERATOR_REPORTS_ROLES.print,OPERATOR_REPORTS_ROLES.readonly],
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "DEPRECIATION_REPORTS_MENU.DEPRECIATION_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: ["Admin",DEPRECIATION_REPORTS_ROLES.create,DEPRECIATION_REPORTS_ROLES.delete,DEPRECIATION_REPORTS_ROLES.edit,DEPRECIATION_REPORTS_ROLES.export,DEPRECIATION_REPORTS_ROLES.print,DEPRECIATION_REPORTS_ROLES.readonly,
          DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.create,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.delete,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.edit,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.export,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.print,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.readonly,
          DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.create,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.delete,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.edit,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.export,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.print,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.readonly,
          DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.create,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.delete,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.edit,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.export,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.print,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.readonly],
        submenu: [
          {
            path: "reports/depreciationReports/register-monthly-report",
            title: "DEPRECIATION_REPORTS_MENU.ASSET_DEPRECIATION_REGISTER_MONTHLY_REPORT",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: ["Admin",DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.create,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.delete,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.edit,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.export,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.print,DEPRECIATION_REGISTER_MONTHLY_REPORTS_ROLES.readonly],
            submenu: [],
          },
          {
            path: "reports/depreciationReports/detail-summary-report",
            title: "DEPRECIATION_REPORTS_MENU.DETAIL_SUMMARY_REPORT",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: ["Admin",DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.create,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.delete,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.edit,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.export,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.print,DEPRECIATION_DETAIL_SUMMARY_REPORT_ROLES.readonly],
            submenu: [],
          },
          {
            path: "reports/depreciationReports/category-summary-report",
            title: "DEPRECIATION_REPORTS_MENU.CATEGORY_SUMMARY_REPORT",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: ["Admin",DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.create,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.delete,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.edit,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.export,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.print,DEPRECIATION_CATEGORY_SUMMARY_REPORT_ROLES.readonly],
            submenu: [],
          },
        ],
      },
      // {
      //   path: "maintanance/next-phase",
      //   title: "Custom Reports",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },
    ],
  },

  {
    path: "",
    title: "MASTER_MENU.MASTER",
    iconType: "material-icons-two-tone",
    icon: "table_view",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ "Admin",ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_CREATE,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_DELETE,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_EDIT,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_EXPORT,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_PRINT,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_READONLY,
          ROLES_LIST.COMMON_MASTER_COUNTRY_CREATE,ROLES_LIST.COMMON_MASTER_COUNTRY_EDIT,ROLES_LIST.COMMON_MASTER_COUNTRY_EXPORT,ROLES_LIST.COMMON_MASTER_COUNTRY_PRINT,ROLES_LIST.COMMON_MASTER_COUNTRY_READONLY,ROLES_LIST.COMMON_MASTER_COUNTRY_DELETE,
          ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_CREATE,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_EDIT,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_EXPORT,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_PRINT,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_READONLY,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_DELETE,
          ROLES_LIST.MASTER_CONTACT_CREATE, ROLES_LIST.MASTER_CONTACT_DELETE, ROLES_LIST.MASTER_CONTACT_EDIT, ROLES_LIST.MASTER_CONTACT_EXPORT, ROLES_LIST.MASTER_CONTACT_PRINT, ROLES_LIST.MASTER_CONTACT_READONLY,
          ROLES_LIST.CONTACT_MASTER_DEPARTMENT_CREATE,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_DELETE,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_EDIT,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_EXPORT,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_PRINT,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_READONLY,
          ROLES_LIST.CONTACT_MASTER_JOB_TITLE_CREATE,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_EDIT,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_EXPORT,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_PRINT,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_READONLY,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_DELETE,
          ROLES_LIST.MASTER_OPERATOR_CREATE, ROLES_LIST.MASTER_OPERATOR_EDIT, ROLES_LIST.MASTER_OPERATOR_EXPORT, ROLES_LIST.MASTER_OPERATOR_PRINT, ROLES_LIST.MASTER_OPERATOR_READONLY,
          ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_CREATE,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_EDIT,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_EXPORT,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_PRINT,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_READONLY,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_DELETE,
          ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_CREATE,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_EDIT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_EXPORT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_PRINT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_READONLY,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_DELETE,
          ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_CREATE,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_EDIT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_EXPORT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_PRINT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_READONLY,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_DELETE,
          ROLES_LIST.MASTER_OPERATOR_DELETE,
          ROLES_LIST.MASTER_VEHICLES_CREATE, ROLES_LIST.MASTER_VEHICLES_DELETE, ROLES_LIST.MASTER_VEHICLES_EDIT, ROLES_LIST.MASTER_VEHICLES_EXPORT, ROLES_LIST.MASTER_VEHICLES_PRINT, ROLES_LIST.MASTER_VEHICLES_READONLY,
          ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_CREATE, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_EDIT, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_EXPORT, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_PRINT, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_READONLY, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_DELETE,
          ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_DELETE,
          ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_DELETE,
          ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_DELETE,
          ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_DELETE,
          ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_DELETE,
          ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_DELETE,
          ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_DELETE,
          ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_DELETE,
          ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_DELETE,
          ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_DELETE,
          ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_DELETE,
          ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_DELETE,
          ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_CREATE, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_EDIT, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_EXPORT, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_PRINT, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_READONLY, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_DELETE,
          ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_DELETE,
          ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_CREATE, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_EDIT, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_EXPORT, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_PRINT, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_READONLY, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_DELETE,],

    submenu: [{
      path: "",
      title: "MAIN_MENU.VEHICLE_MASTER",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_CREATE, ROLES_LIST.MASTER_VEHICLES_DELETE, ROLES_LIST.MASTER_VEHICLES_EDIT, ROLES_LIST.MASTER_VEHICLES_EXPORT, ROLES_LIST.MASTER_VEHICLES_PRINT, ROLES_LIST.MASTER_VEHICLES_READONLY,
      ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_CREATE, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_EDIT, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_EXPORT, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_PRINT, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_READONLY, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_DELETE,
      ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_DELETE,
      ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_DELETE,
      ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_DELETE,
      ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_DELETE,
      ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_DELETE,
      ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_DELETE,
      ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_DELETE,
      ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_DELETE,
      ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_DELETE,
      ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_DELETE,
      ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_DELETE,
      ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_DELETE,
      ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_CREATE, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_EDIT, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_EXPORT, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_PRINT, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_READONLY, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_DELETE,
      ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_DELETE,
      ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_CREATE, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_EDIT, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_EXPORT, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_PRINT, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_READONLY, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_DELETE
      ],
      submenu: [{
        path: "master/plates",
      title: "MASTER_MENU.PLATE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin", ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_CREATE, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_EDIT, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_EXPORT, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_PRINT, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_READONLY, ROLES_LIST.MASTER_VEHICLES_PLATE_MASTER_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicle-plates/list",
      title: "MASTER_MENU.VEHICLE_PLATE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_PLATE_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicle-ownerships/list",
      title: "MASTER_MENU.VEHICLE_OWNERSHIP",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_OWNERSHIP_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicleYear/list",
      title: "MASTER_MENU.VEHICLE_YEAR",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_YEAR_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicle-make/list",
      title: "MASTER_MENU.VEHICLE_MAKE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MAKE_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicle-model/list",
      title: "MASTER_MENU.VEHICLE_MODEL",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_MODEL_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicle-color/list",
      title: "MASTER_MENU.VEHICLE_COLOR",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_COLOR_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicleType/list",
      title: "MASTER_MENU.VEHICLE_TYPE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin", ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_TYPE_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicleBodyType/list",
      title: "MASTER_MENU.VEHICLE_BODY_TYPE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_BODY_TYPE_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicle-categories/list",
      title: "MASTER_MENU.VEHICLE_CATEGORY",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CATEGORY_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicleClass/list",
      title: "MASTER_MENU.VEHICLE_CLASS",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_CREATE, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_EDIT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_EXPORT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_PRINT, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_READONLY, ROLES_LIST.MASTER_VEHICLES_VEHICLE_CLASS_DELETE],
      submenu: [],
      },
      {
        path: "master/fuelType/list",
      title: "MASTER_MENU.FUEL_TYPE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_FUEL_TYPE_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicle-drive-type/list",
      title: "MASTER_MENU.DRIVE_TYPE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_DRIVE_TYPE_DELETE],
      submenu: [],
      },
      {
        path: "master/brakeSystem/list",
      title: "MASTER_MENU.BRAKE_SYSTEM",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_CREATE, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_EDIT, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_EXPORT, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_PRINT, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_READONLY, ROLES_LIST.MASTER_VEHICLES_BRAKE_SYSTEM_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicle-transmission-type/list",
      title: "MASTER_MENU.TRANSMISSION_TYPE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_CREATE, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_EDIT, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_EXPORT, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_PRINT, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_READONLY, ROLES_LIST.MASTER_VEHICLES_TRANSMISSION_TYPE_DELETE],
      submenu: [],
      },
      {
        path: "master/inspection-checklist",
      title: "INSPECTION_CHECKLIST_MENU.INSPECTION_CHECKLIST",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin", ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_CREATE, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_EDIT, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_EXPORT, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_PRINT, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_READONLY, ROLES_LIST.MASTER_VEHICLES_INSPECTION_CHECKLIST_DELETE],
      submenu: [],
      }
    ],
    },
    {
      path: "",
      title: "MAIN_MENU.OPERATOR_MASTER",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_OPERATOR_CREATE, ROLES_LIST.MASTER_OPERATOR_EDIT, ROLES_LIST.MASTER_OPERATOR_EXPORT, ROLES_LIST.MASTER_OPERATOR_PRINT, ROLES_LIST.MASTER_OPERATOR_READONLY, ROLES_LIST.MASTER_OPERATOR_DELETE,
      ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_CREATE,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_EDIT,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_EXPORT,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_PRINT,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_READONLY,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_DELETE,
      ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_CREATE,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_EDIT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_EXPORT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_PRINT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_READONLY,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_DELETE,
      ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_CREATE,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_EDIT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_EXPORT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_PRINT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_READONLY,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_DELETE,
      ],
      submenu: [
        {
          path: "master/driverCategory/list",
      title: "MASTER_MENU.DRIVER_CATEGORY",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin", ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_CREATE,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_EDIT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_EXPORT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_PRINT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_READONLY,ROLES_LIST.MASTER_OPERATOR_OPERATOR_CATEGORY_DELETE],
      submenu: [],
        },
        {
          path: "master/driverType/list",
            title: "MASTER_MENU.DRIVER_TYPE",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [ "Admin",ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_CREATE,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_EDIT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_EXPORT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_PRINT,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_READONLY,ROLES_LIST.MASTER_OPERATOR_OPERATOR_TYPE_DELETE],
            submenu: [],
        },
        {
          path: "master/holidays",
          title: "MASTER_MENU.HOLIDAYS",
          iconType: "material-icons-two-tone",
          class: "",
          groupTitle: false,
          icon: "",
          badge: "",
          badgeClass: "",
          role: [ "Admin",ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_CREATE,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_EDIT,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_EXPORT,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_PRINT,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_READONLY,ROLES_LIST.MASTER_OPERATOR_HOLIDAYS_DELETE],
          submenu: [],
      },
    ],
    },
    {
      path: "",
      title: "MAIN_MENU.CONTACT_MASTER",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CONTACT_CREATE, ROLES_LIST.MASTER_CONTACT_DELETE, ROLES_LIST.MASTER_CONTACT_EDIT, ROLES_LIST.MASTER_CONTACT_EXPORT, ROLES_LIST.MASTER_CONTACT_PRINT, ROLES_LIST.MASTER_CONTACT_READONLY,
      ROLES_LIST.CONTACT_MASTER_DEPARTMENT_CREATE,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_DELETE,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_EDIT,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_EXPORT,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_PRINT,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_READONLY,
      ROLES_LIST.CONTACT_MASTER_JOB_TITLE_CREATE,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_EDIT,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_EXPORT,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_PRINT,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_READONLY,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_DELETE,
      ],
      submenu:[{
        path: "master/departments/list",
      title: "MASTER_MENU.DEPARTMENT",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.CONTACT_MASTER_DEPARTMENT_CREATE,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_DELETE,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_EDIT,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_EXPORT,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_PRINT,ROLES_LIST.CONTACT_MASTER_DEPARTMENT_READONLY],
      submenu: [],
      },
      {
        path: "master/jobTitle",
      title: "MASTER_MENU.JOB_TITLE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.CONTACT_MASTER_JOB_TITLE_CREATE,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_EDIT,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_EXPORT,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_PRINT,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_READONLY,ROLES_LIST.CONTACT_MASTER_JOB_TITLE_DELETE],
      submenu: [],
      }
      ],
    },
    {
      path: "",
      title: "MAIN_MENU.COMMON_MASTER",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_COMMON_CREATE, ROLES_LIST.MASTER_COMMON_DELETE, ROLES_LIST.MASTER_COMMON_EDIT, ROLES_LIST.MASTER_COMMON_EXPORT, ROLES_LIST.MASTER_COMMON_PRINT, ROLES_LIST.MASTER_COMMON_READONLY,
      ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_CREATE,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_DELETE,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_EDIT,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_EXPORT,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_PRINT,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_READONLY,
      ROLES_LIST.COMMON_MASTER_COUNTRY_CREATE,ROLES_LIST.COMMON_MASTER_COUNTRY_EDIT,ROLES_LIST.COMMON_MASTER_COUNTRY_EXPORT,ROLES_LIST.COMMON_MASTER_COUNTRY_PRINT,ROLES_LIST.COMMON_MASTER_COUNTRY_READONLY,ROLES_LIST.COMMON_MASTER_COUNTRY_DELETE,
      ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_CREATE,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_EDIT,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_EXPORT,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_PRINT,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_READONLY,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_DELETE
      ],
      submenu:[
      {
        path: "master/centralCode/list",
      title: "MASTER_MENU.CENTRAL_CODE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin","PSA",ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_CREATE,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_DELETE,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_EDIT,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_EXPORT,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_PRINT,ROLES_LIST.COMMON_MASTER_CENTRAL_CODE_READONLY,,
      ],
      // role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      // {
      //   path: "master/custom-code-module/list",
      // title: "CUSTOM_CODE_MODULES_MENU.CUSTOM_CODE_MODULE",
      // iconType: "material-icons-two-tone",
      // class: "",
      // groupTitle: false,
      // icon: "",
      // badge: "",
      // badgeClass: "",
      // role: [ "Admin"],
      // // role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      // submenu: [],
      // },
      {
        path: "master/vehicleStateProvince/list",
      title: "MASTER_MENU.VEHICLE_STATE/PROVINCE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_CREATE,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_EDIT,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_EXPORT,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_PRINT,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_READONLY,ROLES_LIST.COMMON_MASTER_STATE_PROVINCE_DELETE],
      submenu: [],
      },
      {
        path: "master/vehicle-country/list",
      title: "CONTACT_MENU.COUNTRY",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.COMMON_MASTER_COUNTRY_CREATE,ROLES_LIST.COMMON_MASTER_COUNTRY_EDIT,ROLES_LIST.COMMON_MASTER_COUNTRY_EXPORT,ROLES_LIST.COMMON_MASTER_COUNTRY_PRINT,ROLES_LIST.COMMON_MASTER_COUNTRY_READONLY,ROLES_LIST.COMMON_MASTER_COUNTRY_DELETE],
      submenu: [],
      },
    ]
    }

    // path: "master/plates",
    //   title: "MASTER_MENU.PLATE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],

    // {
    //   path: "master/vehicle-plates/list",
    //   title: "MASTER_MENU.VEHICLE_PLATE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/departments/list",
    //   title: "MASTER_MENU.DEPARTMENT",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/vehicle-ownerships/list",
    //   title: "MASTER_MENU.VEHICLE_OWNERSHIP",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicleYear/list",
    //   title: "MASTER_MENU.VEHICLE_YEAR",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/vehicle-make/list",
    //   title: "MASTER_MENU.VEHICLE_MAKE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicle-model/list",
    //   title: "MASTER_MENU.VEHICLE_MODEL",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/vehicleStateProvince/list",
    //   title: "MASTER_MENU.VEHICLE_STATE/PROVINCE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicle-color/list",
    //   title: "MASTER_MENU.VEHICLE_COLOR",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/vehicleType/list",
    //   title: "MASTER_MENU.VEHICLE_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicleBodyType/list",
    //   title: "MASTER_MENU.VEHICLE_BODY_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/vehicle-categories/list",
    //   title: "MASTER_MENU.VEHICLE_CATEGORY",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicleClass/list",
    //   title: "MASTER_MENU.VEHICLE_CLASS",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/driverCategory/list",
    //   title: "MASTER_MENU.DRIVER_CATEGORY",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/driverType/list",
    //   title: "MASTER_MENU.DRIVER_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/fuelType/list",
    //   title: "MASTER_MENU.FUEL_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicle-drive-type/list",
    //   title: "MASTER_MENU.DRIVE_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/brakeSystem/list",
    //   title: "MASTER_MENU.BRAKE_SYSTEM",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicle-transmission-type/list",
    //   title: "MASTER_MENU.TRANSMISSION_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/maintenanceClassification/list",
    //   title: "MASTER_MENU.MAINTENANCE_CLASSIFICATION",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin"],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicle-category-assign-list/list",
    //   title: "MASTER_MENU.CATEGORY_ASSIGN", // name should be change
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin"],
    //   submenu: [],
    // },
    // {
    //   path: "master/driverTypeClassAssign/list",
    //   title: "MASTER_MENU.DRIVER_TYPE_VEHICLE_CLASS_ASSIGN",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin"],
    //   submenu: [],
    // },
    // {
    //   path: "master/centralCode/list",
    //   title: "MASTER_MENU.CENTRAL_CODE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/jobTitle",
    //   title: "MASTER_MENU.JOB_TITLE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/holidays",
    //   title: "MASTER_MENU.HOLIDAYS",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/custom-code-module/list",
    //   title: "CUSTOM_CODE_MODULES_MENU.CUSTOM_CODE_MODULE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
  ],
  },

  // {
  //   path: "manageAttachment",
  //   title: "MAIN_MENU.MANAGE_ATTACHMENT",
  //   iconType: "material-icons-two-tone",
  //   icon: "attachment",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [MANAGE_ATTACHMENT_ROLES.create, MANAGE_ATTACHMENT_ROLES.delete, MANAGE_ATTACHMENT_ROLES.edit, MANAGE_ATTACHMENT_ROLES.export, MANAGE_ATTACHMENT_ROLES.print, MANAGE_ATTACHMENT_ROLES.readonly],
  //   submenu: []
  // },

  {
    path: "",
    title: "MAIN_MENU.ADMINISTRATION",
    iconType: "material-icons-two-tone",
    icon: "admin_panel_settings",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ "Admin",ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_CREATE, ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_EDIT,ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_EXPORT,ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_PRINT,ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_READONLY,ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_DELETE,
          ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_CREATE,ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_EDIT,ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_EXPORT,ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_PRINT,ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_READONLY,ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_DELETE,
          ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_CREATE,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_EDIT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_EXPORT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_PRINT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_READONLY,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_DELETE,
          ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_CREATE,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_EDIT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_EXPORT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_PRINT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_READONLY,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_DELETE,
          ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_CREATE,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_EDIT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_EXPORT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_PRINT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_READONLY,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_DELETE,
          ROLES_LIST.ADMINISTRATION_MANAGE_USERS_CREATE,ROLES_LIST.ADMINISTRATION_MANAGE_USERS_EDIT,ROLES_LIST.ADMINISTRATION_MANAGE_USERS_EXPORT,ROLES_LIST.ADMINISTRATION_MANAGE_USERS_PRINT,ROLES_LIST.ADMINISTRATION_MANAGE_USERS_READONLY,ROLES_LIST.ADMINISTRATION_MANAGE_USERS_DELETE,
          ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_CREATE,ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_EDIT,ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_EXPORT,ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_PRINT,ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_READONLY,ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_DELETE,
          ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_CREATE,ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_EDIT,ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_EXPORT,ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_PRINT,ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_READONLY,ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_DELETE,
          ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_CREATE,ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_EDIT,ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_EXPORT,ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_PRINT,ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_READONLY,ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_DELETE,
          ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_CREATE,ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_EDIT,ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_EXPORT,ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_PRINT,ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_READONLY,ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_DELETE,
          ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_CREATE,ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_EDIT,ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_EXPORT,ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_PRINT,ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_READONLY,ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_DELETE
          ],
    submenu: [
      {
        path: "administration/systemApplication",
        title: "ADMINISTRATION_MENU.SYSTEM_APPLICATION",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",
              ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_CREATE, ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_EDIT,ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_EXPORT,ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_PRINT,ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_READONLY,ROLES_LIST.ADMINISTRATION_SYSTEM_APPLICATION_DELETE,],
        submenu: [],
      },
      // {
      //   path: "master/custom-code-module/list",
      // title: "CUSTOM_CODE_MODULES_MENU.CUSTOM_CODE_MODULE",
      // iconType: "material-icons-two-tone",
      // class: "",
      // groupTitle: false,
      // icon: "",
      // badge: "",
      // badgeClass: "",
      // role: [ "Admin"],
      // // role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      // submenu: [],
      // },
      {
        path: "administration/systemModule",
        title: "ADMINISTRATION_MENU.SYSTEM_MODULE",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",
              ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_CREATE,ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_EDIT,ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_EXPORT,ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_PRINT,ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_READONLY,ROLES_LIST.ADMINISTRATION_SYSTEM_MODULE_DELETE],
        submenu: [],
      },
      {
        path: "administration/manageRoles",
        title: "ADMINISTRATION_MENU.MANAGE_ROLES",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",
               ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_CREATE,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_EDIT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_EXPORT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_PRINT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_READONLY,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_DELETE,
               ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_CREATE,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_EDIT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_EXPORT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_PRINT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_READONLY,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_DELETE,
               ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_CREATE,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_EDIT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_EXPORT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_PRINT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_READONLY,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_DELETE],
        submenu: [
          {
            path: "administration/manageRoles/manage-role-classification",
            title: "ADMINISTRATION_MENU.ROLES_CLASSIFICATION",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            // role: [FLEET_ADMIN_ROLE,
            //        ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_CREATE,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_EDIT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_EXPORT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_PRINT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_READONLY,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_CLASSIFICATION_DELETE],
            role:["Admin","PSA"],
                   // role: ["Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
            submenu: [],
            // isHide:!CURRENT_USER_DETAILS?.role?.includes(FLEET_ADMIN_ROLE)
          },
          {
            path: "administration/manageRoles",
            title: "ADMINISTRATION_MENU.ROLES_MANAGEMENT",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: ["Admin",
                  ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_CREATE,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_EDIT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_EXPORT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_PRINT,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_READONLY,ROLES_LIST.ADMINISTRATION_MANAGE_ROLES_ROLE_MANAGEMENT_DELETE],

                  submenu: [],
          }
        ],
      },
      {
        path: "administration/manageUsers",
        title: "ADMINISTRATION_MENU.MANAGE_USERS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",
               ROLES_LIST.ADMINISTRATION_MANAGE_USERS_CREATE,ROLES_LIST.ADMINISTRATION_MANAGE_USERS_EDIT,ROLES_LIST.ADMINISTRATION_MANAGE_USERS_EXPORT,ROLES_LIST.ADMINISTRATION_MANAGE_USERS_PRINT,ROLES_LIST.ADMINISTRATION_MANAGE_USERS_READONLY,ROLES_LIST.ADMINISTRATION_MANAGE_USERS_DELETE],
        submenu: [],
      },
      {
        path: "administration/approvedWorkflows",
        title: "ADMINISTRATION_MENU.APPROVED_WORKFLOWS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",
               ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_CREATE,ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_EDIT,ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_EXPORT,ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_PRINT,ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_READONLY,ROLES_LIST.ADMINISTRATION_APPROVAL_WORKFLOWS_DELETE],
        submenu: [],
      },
      {
        path: "administration/workflowUserGroup",
        title: "ADMINISTRATION_MENU.WORKFLOW_USER_GROUP",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",
               ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_CREATE,ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_EDIT,ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_EXPORT,ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_PRINT,ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_READONLY,ROLES_LIST.ADMINISTRATION_WORKFLOW_USER_GROUP_DELETE],
        submenu: [],
      },
      {
        path: "",
        title: "ADMINISTRATION_MENU.PORTAL_LOGIN",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",
               ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_CREATE,ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_EDIT,ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_EXPORT,ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_PRINT,ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_READONLY,ROLES_LIST.ADMINSITRATION_MAINTAINENCE_ADMIN_PORTAL_DELETE],
        submenu: [],
        isExternal:true,
        type:"PORTAL"
      },
      // {
      //   path: "administration/portal",
      //   title: "ADMINISTRATION_MENU.PORTAL_LOGIN",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
      //   submenu: [],
      //   isExternal:true
      // },
      // {
      //   path: "maintanance/next-phase",
      //   title: "ADMINISTRATION_MENU.APPROVAL_MATRIX",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },
      // {
      //   path: "maintanance/next-phase",
      //   title: "ADMINISTRATION_MENU.SECURITY_MATRIX_OF_USERS",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },
      // {
      //   path: "maintanance/next-phase",
      //   title: "ADMINISTRATION_MENU.SECURITY_MATRIX_OF_ROLES",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },
      // {
      //   path: "administration/activityLog",
      //   title: "ADMINISTRATION_MENU.ACTIVITY_LOG",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },

      {
        path: "administration/companySettings",
        title: "ADMINISTRATION_MENU.COMPANY_SETTING",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",FLEET_ADMIN_ROLE,
              ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_CREATE,ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_EDIT,ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_EXPORT,ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_PRINT,ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_READONLY,ROLES_LIST.ADMINISTRATION_COMPANY_SETTINGS_DELETE],
        // role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
        submenu: [],
      },
      {
        path: "administration/systemSettings",
        title: "SYSTEM_SETTINGS_MENU.SYSTEM_SETTINGS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",
               ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_CREATE,ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_EDIT,ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_EXPORT,ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_PRINT,ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_READONLY,ROLES_LIST.ADMINISTRATION_SYSTEM_SETTINGS_DELETE],
        submenu: [],
      },
      // {
      //   path: "maintanance/next-phase",
      //   title: "ADMINISTRATION_MENU.MAINTENANCE_MANAGEMENT",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },

    ],
  },
];
