import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { ManageRolesGroupedList, PolicyReq, PolicySyncReq, RoleClassification, UserPolicyList,
  UserRoleEditReq, UserRoleReq, manageRolesPaginated, RoleWidget,  UserPolicyWidgets} from '../models/manage-roles.model';
import RoleRepresentation from '@keycloak/keycloak-admin-client/lib/defs/roleRepresentation';
import { LookupModel } from 'src/app/home/master/driver-type-class-assign/models/driver-type-class-assign.model';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';

@Injectable({
  providedIn: 'root'
})
export class ManageRolesService {
  private apiManageUserPolicyUrl = 'api/UserPolicy'
  private apiManageUserRoleUrl = 'api/UserRole'
  private apiSystemModuleUrl = 'api/SystemModule'
  private apiRoleClassificationeUrl = 'api/RoleClassifications'
  private apiRoleWidgetsUrl = 'api/RoleWidgets';
  private apiWidgetUrl = 'api/Widget/getAllWidgets';
  constructor(private baseDataService: BaseDataService) { }

  getUserPolicyList(): Observable<UserPolicyList[]> {
    return this.baseDataService.makeGetCall(`${this.apiManageUserPolicyUrl}`)
  }

  getAllUserPolicyList(baseFilterVm: BaseFilterVm): Observable<manageRolesPaginated | ManageRolesGroupedList[]> {
    return this.baseDataService.makePostCall(`${this.apiManageUserPolicyUrl}/getAllUserPolicy`, baseFilterVm)
  }

  getRolesLookup(): Observable<LookupModel[]> {
    return this.baseDataService.makeGetCall(`${this.apiManageUserPolicyUrl}/lookUp`)
  }

  createPolicy(policyReq: PolicyReq): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiManageUserPolicyUrl}`, policyReq)
  }

  deletePolicy(id: string) {
    return this.baseDataService.makeDeleteCall(`${this.apiManageUserPolicyUrl}/${id}`)
  }

  createRole(userRoleReq: UserRoleReq): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiManageUserRoleUrl}`, userRoleReq)
  }

  updateRole(userRoleEditReq: UserRoleEditReq): Observable<any> {
    return this.baseDataService.makePutCall(`${this.apiManageUserPolicyUrl}`, userRoleEditReq)
  }

  getPolicy(id: string) {
    return this.baseDataService.makeGetCall(`${this.apiManageUserPolicyUrl}/${id}`)
  }

  getKeyCloackPolicy(id: string, companyId: string) {
    var str = '';
    if (companyId != null) {
      str = '?companyId=' + companyId
    }
    return this.baseDataService.makeGetCall(`${this.apiManageUserPolicyUrl}/getKeyCloackPolicy/${id}${str}`)
  }

  getAllKeyCloackRoles(): Observable<RoleRepresentation[]> {
    return this.baseDataService.makeGetCall(`${this.apiManageUserRoleUrl}/GetAllKeyCloackRoles`)
  }

  syncRolesList(policySyncReq: PolicySyncReq): Observable<boolean> {
    return this.baseDataService.makePutCall(`${this.apiManageUserRoleUrl}/SyncRolesPolicy`, policySyncReq)
  }
  getModulesLookupList(): Observable<LookupModel[]> {

    return this.baseDataService.makeGetCall(`${this.apiSystemModuleUrl}/lookUp`)
  }
  getModulesTree( ){
    return this.baseDataService.makeGetCall(`${this.apiSystemModuleUrl}/tree/list`)
  }
  getRoleClassificationLookupList(): Observable<LookupModel[]> {

    return this.baseDataService.makeGetCall(`${this.apiRoleClassificationeUrl}/lookUp`)
  }

  getManageRolesExcel(baseFilterVm: BaseFilterVm) : Observable<any>{
    return this.baseDataService.makePostDownloadCall(`${this.apiManageUserPolicyUrl}/getAllUserPolicyExcel`,baseFilterVm)
  }

  // -----Widget---

  assignWidgets(roleWidget: RoleWidget): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiRoleWidgetsUrl}/assignWidgets`,
      roleWidget
    );
  }

  getWidgetsByUserPolicyId(userPolicyId: string): Observable<UserPolicyWidgets>  {
    return this.baseDataService.makeGetCall(
      `${this.apiRoleWidgetsUrl}/getWidgetsByUserPolicyId?userPolicyId=` +
        userPolicyId
    );
  }

  updateAssignedRoleWidgets(roleWidget: RoleWidget): Observable<any> {
    return this.baseDataService.makePutCall(
      `${this.apiRoleWidgetsUrl}/updateAssignedRoleWidgets`,
      roleWidget
    );
  }

  getAllWidgets(baseFilterVm: BaseFilterVm): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiWidgetUrl}`,
      baseFilterVm
    );
  }

  deleteRoleWidget(id: string) {
    return this.baseDataService.makeDeleteCall(
      `${this.apiRoleWidgetsUrl}/${id}`
    );
  }
}
