import { ConfigService } from '../../config/config.service';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { Router } from '@angular/router';
import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { Role } from 'src/app/core/models/role';
import { LanguageService } from 'src/app/core/service/language.service';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { DirectionService } from 'src/app/core/service/direction.service';
import { NotificationSettingSharedService } from '../../shared/services/notification-setting-shared.service';
import { environment } from '../../../environments/environment'
import { SignalrService } from 'src/app/core/service/signalr.service';
import { FLEET_ADMIN_ROLE, Toast } from 'src/app/shared/data/constants';
import { NotificationsDataService } from 'src/app/core/service/notifications-data.service';
import { NotificationList } from 'src/app/core/models/notification.model';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { ApprovalWorkflowListSharedService } from 'src/app/home/approval-workflow-list/services/approval-workflow-list-shared.service'
import { User } from 'src/app/core/models/user';
import { RequestSharedService } from 'src/app/home/request/services/request-shared.service';
import { VehicleAssignmentSharedService } from 'src/app/home/vehicles/vehicle-assignments/services/vehicle-assignment-shared.service';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { LookupImageModel } from 'src/app/home/master/driver-type-class-assign/models/driver-type-class-assign.model';
import { CompanySettingsService } from 'src/app/home/administration/company-settings/services/company-settings.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, takeUntil } from 'rxjs';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { ResetPasswordSharedService } from 'src/app/shared/services/reset-password-shared.service';

const document: any = window.document;
export interface NotificationModel {
  unreadCount?: number;
  notification?: string;
  secondryNotification: string;
  userId?: string;
  userInformationId?: string;
  type?: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})


export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit, OnDestroy {
  public config: any = {};
  userImg: string;
  homePage: string;
  isNavbarCollapsed = false;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  isRtl = false;
  audioFile: any = null
  notificationModel: NotificationModel = {
    unreadCount: 0,
    notification: "",
    userId: "",
    type: "",
    secondryNotification: ""
  };
  unreadCount: number;
  isLoading: boolean = false;
  notificationList: NotificationList[] = []
  currentNotificationList: NotificationList[] = []
  currentUser: User; 3
  isShowUnread: boolean = false;
  notificationForm: FormGroup;
  currentCompany: LookupImageModel;
  companyLookup: LookupImageModel[] = []
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  isCompaniesLoading: boolean = false;
  currentUrl: string = '';
  page: number = 1;
  perPage: number = 20;
  baseFilterVm: BaseFilterVm = {
    searchText: '',
    groupBy: "",
    sortBy: '',
    filters: [],
    isDescending: false,
    pageIndex: 1,
    pageSize: 20,
    sortByList: []
  }
  userContactData: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private fb: FormBuilder,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    private directionService: DirectionService,
    private notificationSettingSharedService: NotificationSettingSharedService,
    private signalrService: SignalrService,
    private notificationDataService: NotificationsDataService,
    private helperService: HelpersService,
    private approvalWorkflowListSharedService: ApprovalWorkflowListSharedService,
    private requestSharedService: RequestSharedService,
    private vehicleAssignmentSharedService: VehicleAssignmentSharedService,
    private companySettingsService: CompanySettingsService,
    private translateService: TranslateService,
    public resetPasswordSharedService: ResetPasswordSharedService,

  ) {
    super();
    this.currentUrl = window.location.href;
    this.notificationSettingSharedService.getSubjectCompanyListChanged().pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        this.authService.fetchUserCompanies().subscribe((resCompany: LookupImageModel[]) => {
          var isFleetAdmin = this.authService.currentUserValue.role
          localStorage.setItem("userCompanies", JSON.stringify(resCompany));
          this.userContactData = JSON.parse(localStorage.getItem('userContactData')) ?? {};
          this.getUserCompanies();
        });
      });
    // getLanguageChangedSubject
  }
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.svg', lang: 'en' },
    // { text: 'Spanish', flag: 'assets/images/flags/spain.svg', lang: 'es' },
    // { text: 'German', flag: 'assets/images/flags/germany.svg', lang: 'de' },
    { text: 'Arabic', flag: 'assets/images/flags/oman.png', lang: 'ar' }
  ];
  notifications: any[] = [
    {
      message: 'Please check your mail',
      time: '14 mins ago',
      icon: 'mail',
      color: 'nfc-green',
      status: 'msg-unread',
    },
    {
      message: 'Please check your mail',
      time: '14 mins ago',
      icon: 'mail',
      color: 'nfc-green',
      status: 'msg-unread',
    },

  ];
  ngOnInit() {
    this.config = this.configService.configData;
    this.currentUser = this.authService.currentUserValue
    const userRole = this.authService.currentUserValue.role;
    this.userImg = this.authService.currentUserValue.img;
    this.unreadCount = 0;
    this.getUserCompanies()
    this.createNotificationForm()
    this.initializeAudio()
    this.getUnreadCount()

    this.currentCompany = this.authService.getCurrentCompany()
    // if (userRole === Role.Admin) {
    //   this.homePage = 'admin/dashboard/main';
    // } else if (userRole === Role.Teacher) {
    //   this.homePage = 'teacher/dashboard';
    // } else if (userRole === Role.Student) {
    //   this.homePage = 'student/dashboard';
    // } else {
    // }
    this.homePage = 'dashboard';

    this.langStoreValue = localStorage.getItem('lang') ?? 'en';
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/us.svg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.isNavbarCollapsed = true;


    this.signalrService.connect();


    this.signalrService.getMessageObservable().subscribe((message: NotificationModel) => {
      this.notificationModel = message

      if (message.userInformationId == this.authService.currentUserValue.id) {
        if (this.notificationModel.type === "REMINDER") {
          // console.log("messageeeee", message);
          this.getUnreadCount();
          this.playAudio();
        } else if (this.notificationModel.type === "USERLOGOUT") {
          this.logout();
        }else if (this.notificationModel.type === "COMPANYDELETED") {
          this.logout();
        }
        else {
          // console.log("messageeeee2", message);
          this.notificationList = [];
          this.page = 1;
          this.loadNotifications();
          this.getUnreadCount();
          this.playAudio();
          if (this.currentUrl.includes('/approval-workflow-list')) {
            this.approvalWorkflowListSharedService.setsubjectWorkflowListTableChanged(true);
          }
          if (this.currentUrl.includes('/request')) {
            this.requestSharedService.setSubjectRequestListChanged(true);
          }
          if (this.currentUrl.includes('/vehicles/assignments')) {
            this.vehicleAssignmentSharedService.setsubjectVehicleAssignmentCreated(true)
            this.vehicleAssignmentSharedService.setsubjectCategoryTypeChanged("VEHICLE_ASSIGNMENT");
          }
        }

        Toast.fire({
          icon: 'info',
          title: this.langStoreValue == 'ar' ? message?.secondryNotification : message?.notification
        })
      }

    });

  }
  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }
  createNotificationForm() {
    this.notificationForm = this.fb.group({
      isUnread: false
    })
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);

    this.switchDirection(lang == "ar")
    location.reload();
  }
  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      // User has scrolled to the bottom
      if (this.currentNotificationList.length == this.perPage) {
        this.page++
        this.loadNotifications();
      }
      // Perform actions as needed
    }
  }



  setCompany(company: LookupImageModel) {

    Swal.fire({
      title: this.translateService.instant('MAIN_MENU.ARE_YOU_SURE_YOU_WANT_TO_CHANGE_THE_COMPANY'),
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#e0272a',
      cancelButtonColor: 'transparent',
      confirmButtonText: this.translateService.instant('MASTER_MENU.CONFIRM'),
      cancelButtonText: this.translateService.instant('MASTER_MENU.CANCEL'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentCompany = company
        this.authService.setCurrentCompany(company)
        this.changeCompanyRoles()


      }
    })

  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  switchDirection(isRtl: boolean) {
    var isrtl: string = String(isRtl);
    if (
      isrtl === 'false' &&
      document.getElementsByTagName('html')[0].hasAttribute('dir')
    ) {
      document.getElementsByTagName('html')[0].removeAttribute('dir');
      this.renderer.removeClass(this.document.body, 'rtl');
      this.directionService.updateDirection('ltr');
    } else if (
      isrtl === 'true' &&
      !document.getElementsByTagName('html')[0].hasAttribute('dir')
    ) {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      this.renderer.addClass(this.document.body, 'rtl');
      this.directionService.updateDirection('rtl');
    }
    localStorage.setItem('isRtl', isrtl);
    this.isRtl = isRtl;
  }

  setRTLSettings() {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    this.renderer.addClass(this.document.body, 'rtl');
    this.isRtl = true;
    localStorage.setItem('isRtl', 'true');
  }

  setLTRSettings() {
    document.getElementsByTagName('html')[0].removeAttribute('dir');
    this.renderer.removeClass(this.document.body, 'rtl');
    this.isRtl = false;
    localStorage.setItem('isRtl', 'false');
  }

  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.isNavbarCollapsed = false
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.isNavbarCollapsed = true
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  openNotificationSettingModal() {
    this.notificationSettingSharedService.setSubjectOpenNotificationSettingModal('open')
  }

  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }


  ngOnDestroy(): void {
    this.signalrService.disconnect();
  }

  initializeAudio() {

    this.audioFile = new Audio();
    this.audioFile.src = "../../../../../assets/audio/notification.wav";
    // audio.muted = true;
    this.audioFile.load();
  }

  playAudio() {
    //console.log('play')
    var promise = this.audioFile.play();
    //console.log(promise)
    if (promise !== undefined) {
      promise.then(_ => {
        // Autoplay started!
      }).catch(error => {
        // Autoplay was prevented.
        // Show a "Play" button so that user can start playback.

        this.playAudio();
      });
    }
  }
  showUnreadRead(event) {
    this.notificationList = [];
    this.page = 1;
    this.loadNotifications(event.checked)
    this.getUnreadCount()
    this.isShowUnread = event.checked
  }
  markAllAsRead() {
    this.isLoading = true
    this.notificationDataService.markAllAsRead()
      .subscribe((res) => {
        this.isShowUnread = false;
        this.notificationForm.get('isUnread')?.setValue(false);
        // this.loadNotifications()
        this.notificationList = this.notificationList.map(res => {
          res.isRead = true;
          return res
        })
        this.getUnreadCount()
        this.isLoading = false
      }, error => {
        console.log(error);
      }
      )
  }

  bellClick(isUnreadOnly) {
    this.page = 1
    this.loadNotifications(isUnreadOnly)
  }

  loadNotifications(isUnreadOnly = false, isLoadInitial = false) {
    this.baseFilterVm = {
      searchText: '',
      groupBy: "",
      sortBy: '',
      filters: [],
      isDescending: false,
      pageIndex: this.page,
      pageSize: this.perPage,
      sortByList: []
    }
    this.isLoading = true


    this.notificationDataService.getNotificationsPaginatedList(isUnreadOnly, this.baseFilterVm)
      .subscribe((res) => {
        this.currentNotificationList = res.items;
        if (this.page == 1) {

          this.notificationList = res.items;
        } else {

          this.notificationList = this.notificationList.concat(res.items);
        }

        if (isUnreadOnly) {
          if (res.length == 0) {
            this.notificationForm.get('isUnread').setValue(false);
            this.loadNotifications()
            this.getUnreadCount()
          }
        }
        this.isLoading = false
      }, error => {
        console.log(error);
      }
      )
  }
  getUnreadCount() {
    this.isLoading = true
    this.notificationDataService.getUnreadCount()
      .subscribe((res) => {
        // console.log(res);
        this.unreadCount = res;
        this.isLoading = false
      }, error => {
        console.log(error);
      }
      )
  }
  markAsReadSingleNotification(id: string) {
    this.isLoading = true
    this.notificationDataService.markAsReadById(id)
      .subscribe((res) => {
        this.notificationList = this.notificationList.map(res => {
          if (res.id == id) {
            res.isRead = true
          }
          return res
        })
        this.getUnreadCount()
        this.isLoading = false
      }, error => {})
  }
  handleNotification(id: string) {
    this.markAsReadSingleNotification(id);
    this.router.navigate(['/approval-workflow-list']);
  }
  getFromNow(dateInUtc: Date) {
    return this.helperService.getDateFromDb(dateInUtc).fromNow(true)
  }



  getUserCompanies() {
    this.isCompaniesLoading = true;
    var isFleetAdmin = this.authService.currentUserValue.role
    this.companyLookup = this.authService.getUserCompanies();
    this.authService.setCurrentCompany(JSON.parse(localStorage.getItem("currentCompany")))
    this.currentCompany = this.authService.currentCompany
    if (!this.authService.currentUserValue.role.includes(FLEET_ADMIN_ROLE)) {
      if (this.companyLookup.length == 0) {
        Toast.fire({
          icon: 'error',
          title: this.translateService.instant('SIGNIN_MENU.CONTACT_ADMINISTRATOR'),

        })
        this.logout()
        this.router.navigate(['/authentication/signin']);
      }
    }
    this.isCompaniesLoading = false;
  }

  changeCompanyRoles() {
    var user = this.authService.currentUserValue

    this.router.navigate(['/dashboard']);
    if (user.role.includes(FLEET_ADMIN_ROLE)) {
      setTimeout(() => {
        window.location.reload()
      }, 500);
    } else {
      this.authService.getPolicyRoles(user.id, this.currentCompany?.id ?? null).subscribe((rolesRes) => {
        user.role = rolesRes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.authService.currentUserSubject.next(user)
        setTimeout(() => {
          window.location.reload()
        }, 500);
      })
    }
  }

  resetPassword() {
    let obj = {
      userName: this.authService?.currentUserValue?.username,
      isCurrentUser: true
    }
    this.resetPasswordSharedService.setSubjectOpenResetPasswordModal(obj)
  }
}
