<ng-template #template>
    <h5 class="modal-title pull-left">{{'ADMINISTRATION_MENU.DUPLICATE_ROLES' | translate}}</h5>
    <div class="modal-body p-2">
        <form [formGroup]="duplicateRoleForm">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field class="example-full-width form-input-field">
                        <mat-label>{{'ADMINISTRATION_MENU.ROLE_NAME' | translate}}</mat-label>
                        <input type="text" formControlName="RoleName" matInput required pattern="\S(.*\S)?">
                        <mat-error *ngIf="duplicateRoleForm.get('RoleName').hasError('required')">{{'ADMINISTRATION_MENU.ROLE_NAME_IS_REQUIRED' | translate}}</mat-error>
                        <mat-error *ngIf="duplicateRoleForm.get('RoleName').hasError('pattern')"> {{'MASTER_MENU.WHITESPACE_CANT_CONTAIN_START_AND_END' | translate}}</mat-error>
                    </mat-form-field>
                    <mat-error class="custom-error-msg" *ngIf="roleNameExists">{{'ADMINISTRATION_MENU.ROLE_NAME_EXISTS' | translate}}</mat-error>
                </div>
                <!-- <div class="col-md-12">
                    <mat-form-field class="example-full-width form-input-field">
                        <mat-label>Duplicate Role Name</mat-label>
                        <input type="text" formControlName="DuplicateRole" matInput required>
                        <mat-error *ngIf="duplicateRoleForm.get('DuplicateRole').hasError('required')">Duplicated Role Name is Required</mat-error>
                    </mat-form-field>
                </div> -->
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button mat-stroked-button color="primary" class="btn-border outline-info-btn btn-text-size" (click)="cancelForm()">
            {{'MASTER_MENU.CANCEL' | translate}}<mat-icon>cancel</mat-icon>
          </button>
          <button
            mat-flat-button
            color="primary"
            class="flat-info-btn"
            *ngIf="isLoading"
          >
            <mat-spinner diameter="20"></mat-spinner>
          </button>
          <button mat-flat-button color="primary" class="flat-info-btn btn-text-size" *ngIf="!isLoading" [disabled]="!duplicateRoleForm.valid || roleNameExists ||  isLoading" (click)="saveDuplicateRoles(duplicateRoleForm.value)">
            {{'MASTER_MENU.SAVE' | translate}}<mat-icon>save</mat-icon>
          </button>
    </div>
</ng-template>
