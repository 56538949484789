import { Component, ViewChild, OnInit, OnDestroy, Input } from '@angular/core';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResetPasswordSharedService } from '../../services/reset-password-shared.service';
import { ResetPasswordService } from '../../services/reset-password.service';
import { Toast } from '../../data/constants';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from '../../services/helpers.service';
import { AuthService } from '../../../core/service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  @ViewChild('template', { static: true }) template: any;
  manageUsersResetPasswordDataSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  resetPasswordForm: FormGroup;
  userName: string = "";
  hide: boolean = true;
  hideConform: boolean = true;
  passwordReqBody: any
  isLoading: boolean = false;
  isCurrentUser: boolean = false
  constructor(
    private resetPasswordSharedService: ResetPasswordSharedService,
    private resetPasswordService: ResetPasswordService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService :TranslateService,
    private helpersService: HelpersService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.manageUsersResetPasswordDataSubject = resetPasswordSharedService
      .getSubjectOpenResetPasswordModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((obj: any) => {
        if(obj != null) {
          if (obj?.userName != null) {
            this.userName = obj?.userName;
            this.isCurrentUser = obj.isCurrentUser;
            this.showModal();
          }
        }
      });
  }

  ngOnInit(): void {
    this.createChangePasswordForm();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-md',
    });
  }

  createChangePasswordForm() {
    this.resetPasswordForm = this.fb.group({
      Password: ['', Validators.required],
      ConformPassword: ['', Validators.required],
    });
  }

  resetPassword(formData: any) {
    this.passwordReqBody = {
      username: this.userName,
      password: formData.Password,
      confirmPassword: formData.ConformPassword,
    }

    this.resetPasswordService.changePassword(this.passwordReqBody)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res) => {
          Toast.fire({
            icon:'success',
            title: this.translateService.instant('ADMINISTRATION_MENU.PASSWORD_CHANGED_SUCCESSFULLY')
          })
          if (this.isCurrentUser) {

          }
          this.cancelForm();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }

  cancelForm() {
    this.modalRef.hide();
    this.resetPasswordForm.reset();
  }

  ngOnDestroy(): void {
    this.resetPasswordSharedService.setSubjectOpenResetPasswordModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }
}
